import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Heading, Text, GridItem, SimpleGrid,} from '@chakra-ui/react'
import {useListProducts} from '../../core/hooks'
import {Card} from '../../components/Theme'
import HeaderForCatalogues from './HeaderForCatalogues'
import LoadingSmall from "../../components/LoadingSmall";

const Products = () => {
    const location = useLocation()
    const [products, setProducts] = useState([])
    const history = useHistory()
    const [offset, setOffset] = useState(null)
    const [offsetPage, setOffsetPage] = useState(1)
    const [page, setPage] = useState(1)
    const path = location.pathname.split('/')[2]
    const [isLoading, setIsLoading] = useState(false);
    let condition = {
        condition: {
            filter: '',
            table: path === 'fixtures' ? 'FIXTURES' : 'LAMPS',
            page,
            offset,
        }
    }
    const {data: productsResponse, isLoading: isProductLoading, refetch} = useListProducts(condition)

    const handleSearch = (e) => {
        // setProducts(productsResponse?.data.data.filter((item) => (item.manufacturer).toLowerCase().includes(e.target.value.toLowerCase())))
    }

    useEffect(() => {
        if (!productsResponse) return
        if (page === 1){
            setProducts(productsResponse.data.data?.records ?? [])
        } else {
            setProducts((prevProducts) => [
                ...prevProducts,
                ...productsResponse.data.data?.records ?? [],
            ]);
        }
        setOffset(productsResponse.data.data?.nextOffset ?? "")
        setIsLoading(false);
    }, [productsResponse])

    const loadMoreProducts = () => {
        if (isLoading) return;

        if (page >= 2 && !offset) return
        setIsLoading(true);
        refetch()
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;

            if (scrollPosition >= pageHeight * 0.5 && !isLoading) {
                loadMoreProducts();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoading]);


    return (
        <>
            <HeaderForCatalogues handleSearch={handleSearch} title={path}/>
            <SimpleGrid p="12" w="full" columns={{base: 1, sm: 2, md: 3, lg: 4,}} spacing="8">
                {products?.map((product, index) => (
                    <GridItem colSpan={1} onClick={() => history.push(`/catalogues/product/${path}/${product?.id}`)} key={index}>
                        <Card image={product.fields['Image'] ? product.fields['Image'][0]?.url : ''}>
                            <Heading fontSize="14px" fontFamily='Titillium Web'>{path === 'lamps' ? product.fields['FE Product Description'] : product.fields['FE Product Description']}</Heading>
                            <Text fontSize="12px">{product.fields['FE Product Description'] ? product.fields['FE Product Description'].replace(/(.{60})..+/, "$1…")  : ' '}</Text>
                        </Card>
                    </GridItem>))}
            </SimpleGrid>
            {isLoading && <LoadingSmall />}
        </>
    )
}

export default Products
